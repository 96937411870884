// Modules
import React from "react";
import cloneDeep from "lodash.clonedeep";
import { graphql } from "gatsby";

// Components
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HeroRedesigned from "../../components/heroRedesigned";
import SideMenu from "../../components/sideMenu";
import BlockContent from "../../components/richText";
import PageBuilder from "../../components/pageBuilder";
import Seo from "../../components/seo/seo";

// Styles
import * as blogStyles from "../blogsStyles.module.scss";
import * as styles from "../successFactor/successFactor.module.scss";

// Helpers
import { getVideoDataInPage } from "../../helpers/getVideoDataInPage";
import generateRandomId from "../../helpers/generateRandomId";

//Hooks
import useBreadcrumbs from "../../hooks/useBreadcrumbs";

const UserExperience = ({ data, pageContext }) => {
  const { sanityUExpMainPage, sanityUExpSubPage } = data;

  const { _rawSeo, _rawContent } = (sanityUExpMainPage ?? sanityUExpSubPage)
    ?.tabs;
  const { pageBuilder, pageHero, pageRichText } = _rawContent;
  const { _createdAt, _updatedAt } = sanityUExpMainPage ?? sanityUExpSubPage;
  const video =
    getVideoDataInPage(pageBuilder) || getVideoDataInPage(pageRichText);
  const mainSlug =
    sanityUExpMainPage?.tabs._rawContent.slug.current ??
    sanityUExpSubPage.tabs.content.parent.tabs.content.slug.current;
  const HeroComponent = pageHero?.isRedesigned ? HeroRedesigned : Hero;

  const { navList, breadcrumbsList } = React.useMemo(() => {
    const navList = [
      {
        _key: generateRandomId(),
        urlLabel:
          sanityUExpMainPage?.tabs._rawContent.title ??
          sanityUExpSubPage.tabs.content.parent.tabs.content.title,
        url: "/" + mainSlug,
      },
      ...(
        sanityUExpMainPage?.tabs.content.children ??
        sanityUExpSubPage.tabs.content.parent.tabs.content.children
      ).map(({ tabs }) => ({
        _key: generateRandomId(),
        urlLabel: tabs.content.title,
        url: tabs.content.slug.current,
      })),
    ];
    const breadcrumbsList = cloneDeep(navList);
    breadcrumbsList[0].url = mainSlug;

    return { navList, breadcrumbsList };
  }, [sanityUExpMainPage, sanityUExpSubPage]);

  const breadcrumbs = useBreadcrumbs(breadcrumbsList, false);

  const pageBuilderClasses = {
    textImage: styles.pageBuilderTextImage,
  };

  return (
    <Layout stickyHeader isPage>
      <Seo
        title={pageHero.heroText.titleText}
        image={pageHero?.bgImage}
        breadcrumbs={breadcrumbs}
        path={pageContext?.pagePath}
        datePublished={_createdAt}
        dateModified={_updatedAt}
        video={video}
        {..._rawSeo}
      />
      <HeroComponent
        imageClassName={blogStyles.heroImg}
        breadcrumbs={breadcrumbs}
        image={pageHero.bgImage}
        subTitle={pageHero?.heroText.subtitleText}
        title={pageHero.heroText.titleText}
      />
      <div className={styles.mainContentWrapper}>
        <div>
          <SideMenu
            getPathFunc={(slug) => `/${mainSlug}/${slug}`}
            list={navList}
          />
        </div>
        <div className={styles.richText}>
          {pageRichText && (
            <BlockContent
              blocks={pageRichText}
              customClassName={styles.gridImgTextContainer}
            />
          )}
        </div>
      </div>
      <div className={styles.pageBuilder}>
        {pageBuilder && (
          <PageBuilder
            classNamesObj={pageBuilderClasses}
            pageBuilderContents={pageBuilder}
          />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    sanityUExpMainPage(
      tabs: { content: { slug: { current: { eq: $slug } } } }
    ) {
      _createdAt
      _updatedAt
      tabs {
        content {
          title
          children {
            tabs {
              content {
                slug {
                  current
                }
                title
              }
            }
          }
        }
        _rawSeo(resolveReferences: { maxDepth: 10 })
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
    }

    sanityUExpSubPage(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _updatedAt
      _createdAt
      tabs {
        content {
          slug {
            current
          }
          parent {
            tabs {
              content {
                slug {
                  current
                }
                children {
                  tabs {
                    content {
                      slug {
                        current
                      }
                      title
                    }
                  }
                }
                title
              }
            }
          }
        }
        _rawContent(resolveReferences: { maxDepth: 10 })
        _rawSeo(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;

export default UserExperience;
